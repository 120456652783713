<script setup lang="ts">
  import { useVuelidate, ErrorObject, ValidationArgs } from '@vuelidate/core'
  import { syncRefs } from '@vueuse/core'
  import { computed, ref, watch, unref } from 'vue'
  import BaseClearable from './BaseClearable.vue'
  import type { ClassProp } from '../types/props'

  defineOptions({
    inheritAttrs: false,
  })

  const props = withDefaults(
    defineProps<{
      modelValue: unknown
      name: string
      /**
       * Pass explicit `null` only if you have detached label somewhere else
       * that is bound to the input by `name`
       */
      label: string | null
      emitOnChange?: boolean
      validation?: ValidationArgs
      validateOnCreated?: boolean
      errorAsTooltip?: boolean
      /**
       * @deprecated use validation instead
       */
      errors?: Pick<ErrorObject, '$message'>[]
      inputClass?: ClassProp
      labelClass?: ClassProp
      wrapperClass?: ClassProp
      containerClass?: ClassProp
      clearablePosition?: 'after-prefix' | 'before-suffix'
    }>(),
    {
      errorAsTooltip: false,
      errors: () => [],
      validation: () => ({}),
      validateOnCreated: false,
      emitOnChange: false,
      inputClass: undefined,
      labelClass: undefined,
      wrapperClass: undefined,
      containerClass: undefined,
      clearablePosition: undefined,
    }
  )

  /**
   * @description The localValue updates when the `modelValue` changes as well as when the input value changes. This ensures that the validation is always up to date.
   */
  const localValue = ref(props.modelValue)
  syncRefs(
    computed(() => props.modelValue),
    localValue
  )

  const $v = useVuelidate(
    computed(() => ({ localValue: props.validation })),
    { localValue }
  )

  if (props.validateOnCreated) {
    $v.value.$touch()
  }

  const isError = computed(() => $v.value.$error || !!props.errors?.length)
  // Reset $dirty state when $error is resolved
  watch(isError, async (isError) => {
    if (!isError) {
      $v.value.$reset()
    }
  })

  const emit = defineEmits<{
    (e: 'update:modelValue', value: unknown): void
    (e: 'focus', value: FocusEvent): void
  }>()

  const emitModelValue = async (value: unknown) => {
    localValue.value = value

    if (await $v.value.$validate()) {
      // Make sure the value is not dirty after successful validation
      $v.value.$reset()
      emit('update:modelValue', value)
    }
  }

  const emitModelValueFromEvent = async (
    event: Event,
    isOnChangeMode: boolean
  ) => {
    if (props.emitOnChange === isOnChangeMode) {
      emitModelValue((event.target as HTMLInputElement).value)
    }
  }

  const clear = () => emitModelValue('')

  const input = ref<HTMLInputElement>()
  const blurInput = async () => {
    // Prevent blur if there is an error so the user can keep editing the input
    if (await $v.value.$validate()) {
      input.value?.blur()
    }
  }

  defineExpose({ input })

  const errorMessage = computed(() => {
    return (
      unref($v.value.$errors[0]?.$message) ?? props.errors[0]?.$message ?? ''
    )
  })
</script>

<template>
  <div
    v-tooltip="errorAsTooltip && isError ? errorMessage : undefined"
    class="has-disabled:not-allowed flex cursor-text flex-col gap-1.5 text-base font-light"
    :class="[isError ? 'text-red-600' : 'text-white-var', containerClass]"
    data-testid="base-input-container"
    @click.stop="input?.focus()"
  >
    <slot :label name="label">
      <label
        v-if="label"
        class="whitespace-nowrap text-left"
        :class="labelClass"
        data-testid="base-input-label"
        :for="name"
      >
        {{ label }}
      </label>
    </slot>
    <div
      class="base-control bg-black-var focus-within:border-white-var relative flex items-center gap-2"
      :class="[
        isError ? 'border-red-600' : 'border-gray-var-600',
        wrapperClass,
      ]"
      data-testid="base-input-wrapper"
    >
      <slot name="prefix">
        <div
          v-if="$slots['prefix-icon']"
          class="shrink-0"
          data-testid="prefix-icon-container"
        >
          <slot name="prefix-icon" />
        </div>
      </slot>

      <BaseClearable
        v-if="clearablePosition === 'after-prefix' && localValue"
        class="shrink-0"
        :class="{ invisible: !localValue }"
        @click="clear"
      />

      <!-- eslint-disable-next-line vue/no-restricted-html-elements -- it's base input -->
      <input
        :id="name"
        ref="input"
        v-model="localValue"
        aria-describedby="error"
        :aria-invalid="isError"
        class="cursor-inherit placeholder-gray-var-400 w-full flex-shrink border-none bg-[inherit] p-0 text-base font-normal focus:outline-none focus:ring-0 focus-visible:outline-none"
        :class="inputClass"
        data-testid="base-input"
        v-bind="$attrs"
        @change="emitModelValueFromEvent($event, true)"
        @focus="$emit('focus', $event)"
        @input="emitModelValueFromEvent($event, false)"
        @keypress.enter="blurInput"
      />

      <BaseClearable
        v-if="clearablePosition === 'before-suffix'"
        class="shrink-0"
        :class="{ invisible: !localValue }"
        @click="clear"
      />

      <slot name="suffix">
        <div
          v-if="$slots['suffix-icon']"
          class="shrink-0"
          data-testid="suffix-icon-container"
        >
          <slot name="suffix-icon" />
        </div>
      </slot>
    </div>
    <template v-if="isError && !errorAsTooltip">
      <slot :error="$v.$errors[0] ?? errors[0]" name="error">
        <div id="error" class="text-left" data-testid="error-label">
          {{ errorMessage }}
        </div>
      </slot>
    </template>
  </div>
</template>

<style scoped>
  input[type='password'] {
    @apply px-0;
  }
</style>
